import { PageNotFoundIllustration } from '@assets/illustrations'
import { getLanguage } from '@i18n'
import { PrimaryButton } from '@uikit/molecules'
import { Metadata } from '@uikit/organisms'
import { useRouter } from 'next/router'

const NotFound = () => {
  const router = useRouter()

  const onClickGoToHome = () => {
    router.replace('/')
  }

  return (
    <>
      <Metadata
        title={getLanguage() === 'en' ? 'Not found' : 'Não encontrado'}
        description={
          getLanguage() === 'en' ? 'Page not found' : 'Página não encontrada'
        }
      />

      <div>
        <PageNotFoundIllustration className="h-[320px] w-[300px] lg:w-[450px]" />
        <div className="mt-10 flex justify-center lg:mt-16">
          <PrimaryButton
            title={getLanguage() === 'en' ? 'Go back home' : 'Voltar para casa'}
            onClick={onClickGoToHome}
            size="middle"
          />
        </div>
      </div>
    </>
  )
}
export default NotFound
